import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageTitle from "../components/PageTitle"
import PostList from "../components/PostList"
import Seo from "../components/seo"

class tag_template extends Component {
  render() {
    return (
      <Layout>
        <Seo
          title={this.props.pageContext.tag.replace(
            /^\w/,
            c => `${c.toUpperCase()}`
          )}
          description={`${this.props.pageContext.tag.replace(
            /^\w/,
            c => `${c.toUpperCase()}`
          )} Tutorials | Blog , Learn how to code and improve `}
          keywords={this.props.data.allMarkdownRemark.edges.map(post =>
            post.node.frontmatter.categories
              .concat(post.node.frontmatter.tags)
              .join(",")
          )}
        />
        <PageTitle
          pageTitle={this.props.pageContext.tag.replace(/^\w/, c =>
            c.toUpperCase()
          )}
        />
        <div className="post-background">
          <div className="post-container">
            {this.props.data.allMarkdownRemark.edges.map(post => (
              <PostList post={post} key={post.node.id} />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const tagQuery = graphql`
  query tagIndexQuery($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
            categories
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 295, height: 200) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          excerpt(pruneLength: 235)
          html
        }
      }
    }
  }
`

export default tag_template
